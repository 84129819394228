<template>
  <div class="examination" v-show="showExData" style="display: none">
    <van-nav-bar
      :title="examinTitle"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="banner"></div>
    <div class="bigContainer" :class="can_switch_pre ? 'small_content' : ''">
      <!-- 标题 -->
      <div
        class="qsTypeTit"
        style="
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px 0;
        "
      >
        <span style="font-size: 18px; font-weight: bold" class="typeName"
          >{{ qsTypeIndexArr[qsList1[hisIdx].qsTypeIndex] }}、{{
            qsList1[hisIdx].qsType
          }}</span
        >
        <span
          class="typeTime"
          style="
            background: #f2840e;
            height: 26px;
            display: flex;
            align-items: center;
            border-radius: 26px;
            font-size: 16px;
            color: #fff;
            padding: 0 12px;
          "
          v-if="testet"
        >
          <img
            style="width: 16px; height: 16px"
            src="@/assets/img/daojishitubiao.png"
          />
          <span style="margin-left: 9px">{{ daojishiStr2 }}</span>
        </span>
      </div>
      <!-- 标题线 -->
      <div
        class="qsTypeTitLine"
        style="width: 100%; height: 1px; background: #ddd"
      ></div>
      <div
        class="qsTypeTitInfo"
        style="
          height: 46px;
          display: flex;
          align-items: center;
          padding: 0 15px;
        "
      >
        <span
          class="grade"
          style="
            background: #f4f4f4;
            padding: 5px;
            font-size: 12px;
            color: #818181;
            border-radius: 4px;
          "
          v-if="
            qsList1[hisIdx].qsType == '单选题' ||
            qsList1[hisIdx].qsType == '多选题' ||
            qsList1[hisIdx].qsType == '判断题'
          "
        >
          {{
            `共${qsList1[hisIdx].icount}题，每题${qsList1[hisIdx].score}分，共${qsList1[hisIdx].totalscore}分`
          }}
        </span>
        <span
          class="grade"
          style="
            background: #f4f4f4;
            padding: 5px;
            font-size: 12px;
            color: #818181;
            border-radius: 4px;
          "
          v-if="
            qsList1[hisIdx].qsType == '填空题' ||
            qsList1[hisIdx].qsType == '问答题'
          "
        >
          {{
            `共${qsList1[hisIdx].icount}题，共${qsList1[hisIdx].totalscore}分`
          }}
        </span>
      </div>
      <div v-if="qsList1.length > 0" class="content" style="padding: 0 15px">
        <!-- 题目内容详情 -->
        <div class="qsContent">
          <div class="qsTit" style="white-space: pre-wrap">
            {{ `${qsList1[hisIdx].sqnum}.${qsList1[hisIdx].qstitle}` }}
            <strong
              style="color: red"
              v-if="
                qsList1[hisIdx].select_count > 0 &&
                qsList1[hisIdx].select_count != checkList.length &&
                qsList1[hisIdx].qsflag == 1
              "
            >
              (该题必须{{ qsList1[hisIdx].select_count }}个答案)
            </strong>
          </div>
          <!-- 图片大小 width="10rem"
                   height="10rem" -->
          <div class="title_pic" v-show="qsList1[hisIdx].pic_guid">
            <van-image
              fit="contain"
              :src="
                qsList1[hisIdx].pic_guid
                  ? filesvrurl + '/' + qsList1[hisIdx].pic_guid + '.png'
                  : ''
              "
              @click="
                imagePreview(
                  filesvrurl + '/' + qsList1[hisIdx].pic_guid + '.png'
                )
              "
            />
          </div>
          <van-radio-group
            class="singleChoice"
            v-model="radioAs"
            @change="radioAnswer"
            v-if="qsList1[hisIdx].qsType == '单选题'"
          >
            <div v-for="(opt, opIdx) in qsList1[hisIdx].options" :key="opIdx">
              <van-radio :name="opt.id"
                ><span class="iico">{{ abList[opIdx] }}</span
                >{{ opt.c }}</van-radio
              >
              <div class="option_pic" v-show="qsList1[hisIdx].item_pics[opIdx]">
                <van-image
                  width="100%"
                  height="100%"
                  :src="
                    qsList1[hisIdx].item_pics[opIdx]
                      ? filesvrurl +
                        '/' +
                        qsList1[hisIdx].item_pics[opIdx] +
                        '.png'
                      : ''
                  "
                  @click="
                    imagePreview(
                      filesvrurl +
                        '/' +
                        qsList1[hisIdx].item_pics[opIdx] +
                        '.png'
                    )
                  "
                />
              </div>
            </div>
          </van-radio-group>
          <!-- 多选题 -->
          <van-checkbox-group
            v-model="checkList"
            v-else-if="qsList1[hisIdx].qsType == '多选题'"
            @change="checkboxAnswer"
          >
            <div v-for="(opt, opIdx) in qsList1[hisIdx].options" :key="opIdx">
              <van-checkbox :name="opt.id"
                ><span class="iico">{{ abList[opIdx] }}</span
                >{{ opt.c }}</van-checkbox
              >
              <div class="option_pic" v-show="qsList1[hisIdx].item_pics[opIdx]">
                <van-image
                  width="100%"
                  height="100%"
                  :src="
                    qsList1[hisIdx].item_pics[opIdx]
                      ? filesvrurl +
                        '/' +
                        qsList1[hisIdx].item_pics[opIdx] +
                        '.png'
                      : ''
                  "
                  @click="
                    imagePreview(
                      filesvrurl +
                        '/' +
                        qsList1[hisIdx].item_pics[opIdx] +
                        '.png'
                    )
                  "
                />
              </div>
            </div>
          </van-checkbox-group>
          <!-- 判断题 -->
          <van-radio-group
            class="judge"
            v-model="judgeAs"
            v-if="qsList1[hisIdx].qsType == '判断题'"
            @change="judgeAnswer"
          >
            <van-radio name="1">{{
              $t("examination.examinationTxt1")
            }}</van-radio>
            <div class="option_pic" v-show="qsList1[hisIdx].item_pics[0]">
              <van-image
                width="100%"
                height="100%"
                :src="
                  qsList1[hisIdx].item_pics[0]
                    ? filesvrurl + '/' + qsList1[hisIdx].item_pics[0] + '.png'
                    : ''
                "
                @click="
                  imagePreview(
                    filesvrurl + '/' + qsList1[hisIdx].item_pics[0] + '.png'
                  )
                "
              />
            </div>
            <van-radio name="0">{{
              $t("examination.examinationTxt2")
            }}</van-radio>
            <div class="option_pic" v-show="qsList1[hisIdx].item_pics[1]">
              <van-image
                width="100%"
                height="100%"
                :src="
                  qsList1[hisIdx].item_pics[1]
                    ? filesvrurl + '/' + qsList1[hisIdx].item_pics[1] + '.png'
                    : ''
                "
                @click="
                  imagePreview(
                    filesvrurl + '/' + qsList1[hisIdx].item_pics[1] + '.png'
                  )
                "
              />
            </div>
          </van-radio-group>
          <!-- 填空题 -->
          <van-cell-group v-if="qsList1[hisIdx].qsType == '填空题'">
            <!-- 填空题 禁用复制粘贴-->
            <div v-if="forbid_mobile_paste">
              <van-field
                v-model="completions[tkIdx]"
                :label="'空格' + parseInt(tkIdx + 1)"
                :placeholder="$t('examination.examinationTxt3')"
                v-for="(tk, tkIdx) in qsList1[hisIdx].qstitle.split('____')
                  .length - 1"
                :key="tkIdx"
                @paste.native.capture.prevent="() => {}"
                @copy.native.capture.prevent="() => {}"
              />
            </div>
            <div v-else>
              <van-field
                v-model="completions[tkIdx]"
                :label="'空格' + parseInt(tkIdx + 1)"
                :placeholder="$t('examination.examinationTxt3')"
                v-for="(tk, tkIdx) in qsList1[hisIdx].qstitle.split('____')
                  .length - 1"
                :key="tkIdx"
              />
            </div>
          </van-cell-group>
          <!-- 简答题 -->
          <!-- 简答题 禁用复制粘贴-->
          <div v-if="forbid_mobile_paste">
            <van-field
              class="brief"
              v-if="qsList1[hisIdx].qsType == '问答题'"
              v-model="questionAs"
              rows="10"
              autosize
              label=""
              type="textarea"
              maxlength="500"
              :placeholder="$t('examination.examinationTxt4')"
              show-word-limit
              @paste.native.capture.prevent="() => {}"
              @copy.native.capture.prevent="() => {}"
            />
          </div>
          <div v-else>
            <van-field
              class="brief"
              v-if="qsList1[hisIdx].qsType == '问答题'"
              v-model="questionAs"
              rows="10"
              autosize
              label=""
              type="textarea"
              maxlength="500"
              :placeholder="$t('examination.examinationTxt4')"
              show-word-limit
            />
          </div>

          <!-- 上传题 -->
          <!-- <van-uploader v-if="qsList[bgIdx].qsType == '附件题'"
                      v-model="item.answer"
                      multiple
                      :max-count="1">
          <van-button icon="plus"
                      type="info">上传文件</van-button>
        </van-uploader> -->
        </div>
      </div>
    </div>
    <!-- dwdwedwddwe -->
    <div class="footer">
      <div class="footer-0">
        <div class="footer-1">
          <div class="footer-3" @click="prev">
            <img
              style="width: 28px; height: 28px"
              src="@/assets/img/shangyiti.png"
              alt=""
            />
            <div
              class="footer-2 active"
              :class="
                can_switch_pre && !qsList1[hisIdx].isFirst ? '' : 'disabled'
              "
            >
              上一题
            </div>
          </div>
        </div>
        <div class="footer-1">
          <div class="footer-3" @click="next">
            <img
              style="width: 28px; height: 28px"
              src="@/assets/img/xiayiti.png"
              alt=""
            />
            <div
              class="footer-2 active"
              :class="
                !cansave || (qsList1[hisIdx].isLast && can_switch_pre)
                  ? 'disabled'
                  : ''
              "
            >
              下一题
            </div>
          </div>
        </div>
        <div class="footer-1" @click="toDaTi">
          <img
            style="width: 28px; height: 28px"
            src="@/assets/img/datika.png"
            alt=""
          />
          <div class="footer-2">答题卡</div>
        </div>
        <div
          v-if="can_switch_pre"
          @click="onSubmit"
          class="footer-1"
          
        >
          <van-icon size="28" color="#1989fa" name="success" />
          <div style="color:#1989fa;" class="footer-2">提交</div>
        </div>
      </div>

      <!-- <div class="footer_button">
        <van-button plain type="info" :disabled="!can_switch_pre" @click="prev"
          >上一题</van-button
        >
        <van-button plain type="info" :disabled="!cansave" @click="next">{{
          $t("examination.examinationTxt5")
        }}</van-button>
      </div>
      <div
        class="footer_datika"
        style="margin-left: -30px; width: 60px"
        @click="toDaTi"
      >
        <img
          style="width: 60px; height: 60px"
          src="@/assets/img/dwdwedwddwe.png"
        />
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 12px;
            height: 22px;
            color: #2d2d2d;
          "
        >
          答题卡
        </div>
      </div> -->
    </div>
    <exitPrompt ref="exitPrompt" :noAnswer="noAnswer" :toPath="toPath" />
    <div class="daTiKaContainer" v-if="isDaTiKa">
      <div class="daTiKaContainerBox">
        <img
          style="
            width: 60px;
            height: 60px;
            position: absolute;
            left: 50%;
            margin-left: -30px;
            top: -20px;
          "
          src="@/assets/img/dwdwedwddwe.png"
        />
        <div
          class="daTiKaContainerBoxTop"
          style="height: 40px; position: relative"
        >
          <van-icon
            style="position: absolute; right: 0; top: 0px"
            name="cross"
            @click="closeDaTiKa"
          />
        </div>
        <div class="daTiKaContainerBoxTop2" style="margin-top: 5px">
          <div class="ipiipipi">
            <div class="ipiipipiTop">
              <div
                style="
                  width: 6px;
                  height: 6px;
                  background: #d9dde5;
                  border-radius: 50%;
                "
              ></div>
              <div style="margin-left: 5px; font-size: 12px; color: #a4a4a4">
                未答
              </div>
            </div>
            <div class="ipiipipiTop" style="margin: 0 15px">
              <div
                style="
                  width: 6px;
                  height: 6px;
                  background: #218fff;
                  border-radius: 50%;
                "
              ></div>
              <div style="margin-left: 5px; font-size: 12px; color: #a4a4a4">
                已答
              </div>
            </div>
            <div class="ipiipipiTop">
              <div
                style="
                  width: 6px;
                  height: 6px;
                  background: #ff9518;
                  border-radius: 50%;
                "
              ></div>
              <div style="margin-left: 5px; font-size: 12px; color: #a4a4a4">
                当前
              </div>
            </div>
          </div>
        </div>
        <div class="daTiKaContainerBoxContent">
          <div
            class="divdivdiv"
            v-for="(value, key) in arrayGroupByList"
            :key="key"
          >
            <div class="ppppppp">
              <div class="shuxian"></div>
              <span class="spanspan">{{ value[0].qsType }}</span>
            </div>
            <ul class="ulliulliulli">
              <li
                v-for="(item, index) in value"
                :key="index"
                :class="[
                  item.nowSelect ? 'nowSelectnowSelect' : '',
                  item.qsres ? 'nowQsRes' : '',
                ]"
                @click="selectNumFun(value, key, item, index)"
              >
                <span>{{ index + 1 }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview, Dialog, Toast } from "vant";
import exitPrompt from "./exitPrompt.vue";
import submitPrompt from "./submitPrompt.vue";
import {
  getCourseTestTotal,
  createUserTestInfo,
  saveTestQsInfo,
  getPreQsItemInfo,
  autoPostPaper,
  getEmpTestQsDtInfo,
  postUserTestInfo,
} from "@api/wxpx.js";
export default {
  components: {
    exitPrompt,
    submitPrompt,
  },
  data() {
    let from = this.$route.query.from || "";
    let guid = this.$route.query.guid || "";
    return {
      guid,
      from,
      userInfo: localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {},
      paperid: this.$route.query.paperid,
      mautoid: this.$route.query.mautoid, // 培训实施记录id
      teststyle: this.$route.query.teststyle, // 培训实施记录id
      examinTitle: "", // 考试标题
      lastid: "", // 上次中断的试题id（如果等于-1表示答题完成）
      totalList: [], // 考试的题型信息
      hisIdx: 0, // 历史记录题目在数组中的index
      toPath: {},
      bigOrder: [
        this.$t("examination.examinationTxt6"),
        this.$t("examination.examinationTxt7"),
        this.$t("examination.examinationTxt8"),
        this.$t("examination.examinationTxt9"),
        this.$t("examination.examinationTxt10"),
        this.$t("examination.examinationTxt11"),
        this.$t("examination.examinationTxt12"),
      ],
      abList: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      radioAs: "", // 单选题答案
      checkList: [], // 多选答案
      judgeAs: "", // 判断题答案(0和1,传参时转换)
      completions: [], // 填空题答案
      questionAs: "", // 问答题答案
      // cansave: false, // 是否可以下一题
      totalscore: 0, // 试卷总得分
      isOvertime: false, // 超时结束
      isfinish: false, // 答题结束
      qsList1: [],
      showExData: false,
      filesvrurl: "", // 图片url前缀
      chainaIndex: "一",
      chainaSelect: "",
      qsTypeIndexArr: ["一", "二", "三", "四", "五", "六"],
      finishInfo: "", // 考试结束的提示文字
      lastinfos: "",
      can_switch_pre: false,
      option_outof_order: false,
      indexAdd: 1,
      testet: null, // 考试结束日期
      daojishiStr: "剩余时间：0时0分0秒",
      daojishiStr2: "00:00:00",
      QRTimer: null,
      isTimeout: false,
      forbid_mobile_paste: false, // 禁用移动端粘贴内容
      when_switch_page_auto_post: false, // 移动端页面自动提交试卷
      arrayGroupByList: [],
      noFinish: true,
      lastFlag: false,
      isDaTiKa: false,
      isWcDt: false, // 用户完成答题
      lastflag: 0,
    };
  },
  watch: {
    hisIdx: {
      handler(n) {
        console.log(this.qsList1[n].qsType, "hisIdxhisIdxhisIdxhisIdx");
      },
    },
    isOvertime: {
      handler(n) {
        if (n) {
          let param = {
            paperid: this.paperid,
            mautoid: this.mautoid,
            teststyle: this.teststyle,
            examinTitle: this.examinTitle,
            totalscore: this.totalscore,
            isAnswer: this.qsList1.length - this.noAnswer,
            noAnswer: this.noAnswer,
            finishInfo: this.finishInfo,
            lastinfos: this.lastinfos,
            from: this.from,
            guid: this.guid,
          };
          this.$router.push({ path: "/submitPage", query: { param: param } });
          // this.$refs.submitPrompt.show = true
        }
      },
    },
    isfinish: {
      handler(n) {
        if (n) {
          let param = {
            paperid: this.paperid,
            mautoid: this.mautoid,
            teststyle: this.teststyle,
            examinTitle: this.examinTitle,
            totalscore: this.totalscore,
            isAnswer: this.qsList1.length - this.noAnswer,
            noAnswer: this.noAnswer,
            finishInfo: this.finishInfo,
            lastinfos: this.lastinfos,
            from: this.from,
            guid: this.guid,
          };
          this.$router.push({ path: "/submitPage", query: { param: param } });
          // this.$refs.submitPrompt.show = true
        }
      },
    },
  },
  computed: {
    cansave() {
      if (
        this.radioAs != "" ||
        this.checkList.length > 0 ||
        this.judgeAs != "" ||
        this.completions.length > 0 ||
        this.questionAs != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 计算试卷有多少题没有答案
    noAnswer() {
      let num = 0;
      if (!this.isfinish) {
        // this.showExData = true;
        num = this.qsList1.length - this.hisIdx;
      }
      return num;
    },
  },
  created() {
    window.T = this;
    // if (this.from == 'mianshi' || this.from == 'dandian')    {
    //   this.toPath = null
    // } else    {
    this.toPath = {
      path: "/examinInfo",
      query: {
        paperid: this.paperid,
        mautoid: this.mautoid,
        teststyle: this.teststyle,
      },
    };
    // }
    this.GetCourseTestTotal();
  },
  mounted() {
    const _this = this;
    // this.$nextTick(() => {
    window.addEventListener("visibilitychange", this.func1);
    window.addEventListener("pagehide", this.func2);
    window.addEventListener("popstate", this.func2);
    window.addEventListener("onunload", this.listenerWindow);
    // });
  },
  beforeDestroy() {
    clearInterval(this.QRTimer);

    window.removeEventListener("visibilitychange", this.func1);
    window.removeEventListener("pagehide", this.func2);
    window.removeEventListener("popstate", this.func2);
    window.removeEventListener("onunload", this.listenerWindow);
  },
  methods: {
    func1() {
      //部分手机可以获取到
      if (document.hidden) {
        this.listenerClose();
      }
    },
    func2() {
      this.listenerClose();
    },
    // 离开页面或退出后处理
    async listenerClose() {
      let _this = this;
      if (_this.when_switch_page_auto_post) {
        await autoPostPaper({
          mautoid: _this.mautoid,
          empid: _this.userInfo.empid,
          teststyle: _this.teststyle,
          paperid: _this.paperid,
          username: _this.userInfo.username,
          guid: this.$route.query.guid || "",
        }).then((res) => {
          const left_switch_times = res.data[0].left_switch_times;
          if (res.data[0].info) {
            Dialog.alert({
              message: res.data[0].info,
              confirmButtonText: left_switch_times <= 0 ? "确认" : "继续答题",
            }).then(() => {
              // on close
              if (left_switch_times <= 0) {
                let toPath = {
                  path: "/examinInfo",
                  query: {
                    paperid: _this.paperid,
                    mautoid: _this.mautoid,
                    teststyle: _this.teststyle,
                  },
                };
                this.$router.push(toPath);
              }
            });
            // Toast.fail(res.data[0].info);
          }
        });
      }
    },
    listenerWindow(event) {
      this.listenerClose();
      event.preventDefault();
      event.returnValue = "是否离开？";
      return true;
    },
    toDaTi() {
      this.isDaTiKa = true;
    },
    closeDaTiKa() {
      this.isDaTiKa = false;
    },
    // 考试剩余时间倒计时
    FreshTime(endData) {
      clearInterval(this.QRTimer);
      // endData = '2023-07-04 16:58:00'
      if (!endData) {
        this.daojishiStr = "剩余时间：0时0分0秒";
        this.daojishiStr2 = "00:00:00";
        this.isTimeout = true;
      } else {
        // 如果有过期时间，则开启倒计时
        this.QRTimer = setInterval(() => {
          const endtime = new Date(endData); //结束时间
          const nowtime = new Date(); //当前时间
          const lefttime = parseInt(
            (endtime.getTime() - nowtime.getTime()) / 1000
          ); // 剩余时间
          if (lefttime > 0) {
            const h = parseInt((lefttime / 3600) % 24); // 剩余小时数
            const m = parseInt((lefttime / 60) % 60); // 剩余分钟数
            const s = parseInt(lefttime % 60); // 剩余秒数
            this.daojishiStr = "剩余时间：" + h + "时" + m + "分" + s + "秒";
            this.daojishiStr2 =
              "" +
              (h < 10 ? "0" + h : h) +
              ":" +
              (m < 10 ? "0" + m : m) +
              ":" +
              (s < 10 ? "0" + s : s);
          } else {
            clearInterval(this.QRTimer);
            this.daojishiStr = "剩余时间：0时0分0秒";
            this.daojishiStr2 = "00:00:00";
            this.isTimeout = true;
          }
        }, 1000);
      }
    },
    // 预览图片
    imagePreview(val) {
      ImagePreview([val]);
    },
    // 判断题
    judgeAnswer(val) {
      // this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 多选题选择答案
    checkboxAnswer(val) {
      // this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 单选题选择答案
    radioAnswer(val) {
      // this.radioAs = val
      // this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 点击返回
    onClickLeft() {
      if (this.noAnswer > 0) {
        this.$refs.exitPrompt.show = true;
      } else {
        // if (this.from == 'mianshi' || this.from == 'dandian')        {
        //   // 从待面试跳转过来

        // } else        {
        // this.$router.push(`/examinInfo/${this.paperid}`);
        this.$router.push({
          path: "/examinInfo",
          query: {
            paperid: item.paperid,
            mautoid: item.autoid,
            teststyle: this.teststyle,
          },
        });
        // }
      }
    },
    selectNumFun(value, key, item, index) {
      if (!this.can_switch_pre) {
        return;
      }
      if (this.isTimeout) {
        // 超时直接返回首页
        Dialog.alert({
          title: "提示",
          message: "考试已超时！",
        }).then(() => {
          this.$router.push("/home");
        });
        // Toast.fail('考试已超时！');
        return;
      }
      let flag = item.qsflag;
      let auid = item.autoid;

      let flag2 = this.qsList1[this.hisIdx].qsflag;
      let auid2 = this.qsList1[this.hisIdx].autoid;

      let params2 = {
        autoid: auid2,
        qsres: "",
        username: this.userInfo.username,
      };
      if (flag2 == 0) {
        // 单选题
        if (this.radioAs) {
          params2.qsres = this.radioAs;
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid2) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.radioAs = "";
        }
      } else if (flag2 == 1) {
        // 多选题
        if (this.checkList.length > 0) {
          params2.qsres = this.checkList.sort().join(",");
          if (
            this.qsList1[this.hisIdx].select_count > 0 &&
            this.qsList1[this.hisIdx].select_count != this.checkList.length
          ) {
            Dialog.alert({
              title: "系统提示",
              message:
                "此题选项必须选择" +
                this.qsList1[this.hisIdx].select_count +
                "个！",
            }).then(() => {});
            return false;
          }
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid2) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.checkList = [];
        }
      } else if (flag2 == 2) {
        // 判断题
        if (this.judgeAs) {
          params2.qsres = this.judgeAs == "0" ? false : true;
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid2) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.judgeAs = "";
        }
      } else if (flag2 == 3) {
        // 填空题
        if (this.completions.length > 0) {
          params2.qsres = this.completions.join("|");
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid2) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.completions = [];
        }
      } else if (flag2 == 4) {
        // 问答题
        if (this.questionAs) {
          params2.qsres = this.questionAs;
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid2) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.questionAs = "";
        }
      } else if (flag2 == 5) {
        // 附件题
      }
      let params = {
        autoid: auid,
        username: this.userInfo.username,
      };
      getEmpTestQsDtInfo(params).then((res) => {
        if (res.data.length > 0) {
          this.isDaTiKa = false;
          const obj = res.data[0];
          const qsres = obj.qsres;
          for (let i = 0; i < this.qsList1.length; i++) {
            this.qsList1[i].nowSelect = false;
          }
          for (let i = 0; i < this.qsList1.length; i++) {
            if (this.qsList1[i].autoid == obj.autoid) {
              this.qsList1[i].nowSelect = true;
            }
          }
          this.qsList1.map((it, index) => {
            if (it.autoid == obj.autoid) {
              this.hisIdx = index;
              const newobj = this.qsList1[this.hisIdx];
              if (newobj.qsflag == 0) {
                this.radioAs = "";
                this.checkList = [];
                this.judgeAs = "";
                this.questionAs = "";
                this.completions = [];
                newobj.options.forEach((item) => {
                  if (qsres == item.id) {
                    this.radioAs = item.id;
                  }
                });
              } else if (newobj.qsflag == 1) {
                this.radioAs = "";
                this.judgeAs = "";
                this.questionAs = "";
                let arr = [];
                this.completions = [];
                newobj.options.forEach((item) => {
                  if (qsres && qsres.indexOf(item.id) != -1) {
                    arr.push(item.id);
                  }
                });
                this.checkList = arr;
              } else if (newobj.qsflag == 2) {
                this.questionAs = "";
                this.radioAs = "";
                this.checkList = [];
                this.completions = [];
                qsres == "false"
                  ? (this.judgeAs = "0")
                  : qsres == "true"
                  ? (this.judgeAs = "1")
                  : (this.judgeAs = "");
                // params.qsres = this.judgeAs == "0" ? false : true;
                // judgeAs
              } else if (newobj.qsflag == 3) {
                // 填空题
                this.radioAs = "";
                this.judgeAs = "";
                this.questionAs = "";
                this.checkList = [];
                this.completions = qsres ? qsres.split("|") : [];
              } else if (newobj.qsflag == 4) {
                this.radioAs = "";
                this.checkList = [];
                this.judgeAs = "";
                this.completions = [];
                this.questionAs = qsres;
              }
            }
          });

          if (this.indexAdd > 1) {
            this.indexAdd--;
          }
        }
      });
    },
    prev() {
      if (this.qsList1[this.hisIdx].isFirst) {
        return false;
      }
      if (!this.can_switch_pre) {
        return false;
      }
      if (this.isTimeout) {
        // 超时直接返回首页
        Dialog.alert({
          title: "提示",
          message: "考试已超时！",
        }).then(() => {
          this.$router.push("/home");
        });
        // Toast.fail('考试已超时！');
        return;
      }
      if (this.hisIdx <= 0) {
        return;
      }
      let flag = this.qsList1[this.hisIdx].qsflag;
      let auid = this.qsList1[this.hisIdx].autoid;
      let params = {
        autoid: auid,
        username: this.userInfo.username, // 后续登录接口有变--username
      };
      let params2 = {
        autoid: auid,
        qsres: "",
        username: this.userInfo.username, // 后续登录接口有变--username
      };
      if (flag == 0) {
        // 单选题
        if (this.radioAs) {
          params2.qsres = this.radioAs;
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              this.lastflag = lastflag;
              if (lastflag == -1) {
                // 答题结束
                this.isWcDt = true;
              }
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.radioAs = "";
        }
      } else if (flag == 1) {
        // 多选题
        if (this.checkList.length > 0) {
          params2.qsres = this.checkList.sort().join(",");
          if (
            this.qsList1[this.hisIdx].select_count > 0 &&
            this.qsList1[this.hisIdx].select_count != this.checkList.length
          ) {
            Dialog.alert({
              title: "系统提示",
              message:
                "此题必须选择" +
                this.qsList1[this.hisIdx].select_count +
                "个答案！",
            }).then(() => {});
            return false;
          }
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              this.lastflag = lastflag;
              if (lastflag == -1) {
                // 答题结束
                this.isWcDt = true;
              }
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.checkList = [];
        }
      } else if (flag == 2) {
        // 判断题
        if (this.judgeAs) {
          params2.qsres = this.judgeAs == "0" ? false : true;

          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              this.lastflag = lastflag;
              if (lastflag == -1) {
                // 答题结束
                this.isWcDt = true;
              }
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.judgeAs = "";
        }
      } else if (flag == 3) {
        // 填空题
        if (this.completions.length > 0) {
          params2.qsres = this.completions.join("|");
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              this.lastflag = lastflag;
              if (lastflag == -1) {
                // 答题结束
                this.isWcDt = true;
              }
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.completions = [];
        }
      } else if (flag == 4) {
        // 问答题
        if (this.questionAs) {
          params2.qsres = this.questionAs;
          saveTestQsInfo(params2).then((res) => {
            for (let i = 0; i < this.qsList1.length; i++) {
              if (this.qsList1[i].autoid == auid) {
                this.qsList1[i].qsres = true;
              }
            }
            let total = res.total;
            if (total.length > 0) {
              this.finishInfo = total[0].finishinfo;
              const iflag = total[0].iflag;
              const lastflag = total[0].lastflag;
              this.lastflag = lastflag;
              if (lastflag == -1) {
                // 答题结束
                this.isWcDt = true;
              }
              if (iflag == -1) {
                // 答题结束
                this.totalscore = total[0].totalscore;
                this.showExData = false;
                this.isfinish = true;
              } else if (lastflag == -2) {
                // 剩余1题
                this.lastFlag = true;
              }
            }
          });
          this.questionAs = "";
        }
      } else if (flag == 5) {
        // 附件题
      }
      getPreQsItemInfo(params).then((res) => {
        if (res.data.length > 0) {
          const obj = res.data[0];
          const qsres = obj.qsres;
          for (let i = 0; i < this.qsList1.length; i++) {
            this.qsList1[i].nowSelect = false;
          }
          for (let i = 0; i < this.qsList1.length; i++) {
            if (this.qsList1[i].autoid == obj.autoid) {
              this.qsList1[i].nowSelect = true;
            }
          }
          this.qsList1.map((it, index) => {
            if (it.autoid == obj.autoid) {
              this.hisIdx = index;
              const newobj = this.qsList1[this.hisIdx];
              if (newobj.qsflag == 0) {
                this.radioAs = "";
                this.checkList = [];
                this.judgeAs = "";
                this.questionAs = "";
                this.completions = [];
                newobj.options.forEach((item) => {
                  if (qsres == item.id) {
                    this.radioAs = item.id;
                  }
                });
              } else if (newobj.qsflag == 1) {
                this.radioAs = "";
                this.judgeAs = "";
                this.questionAs = "";
                let arr = [];
                newobj.options.forEach((item) => {
                  if (qsres.indexOf(item.id) != -1) {
                    arr.push(item.id);
                  }
                });
                this.checkList = arr;
                this.completions = [];
              } else if (newobj.qsflag == 2) {
                this.questionAs = "";
                this.radioAs = "";
                this.checkList = [];
                qsres == "false"
                  ? (this.judgeAs = "0")
                  : qsres == "true"
                  ? (this.judgeAs = "1")
                  : (this.judgeAs = "");
                // params.qsres = this.judgeAs == "0" ? false : true;
                // judgeAs
              } else if (newobj.qsflag == 3) {
                // 填空题
                this.radioAs = "";
                this.judgeAs = "";
                this.questionAs = "";
                this.checkList = [];
                this.completions = qsres ? qsres.split("|") : [];
              } else if (newobj.qsflag == 4) {
                this.radioAs = "";
                this.checkList = [];
                this.judgeAs = "";
                this.completions = [];
                this.questionAs = qsres;
              }
            }
          });
          if (this.indexAdd > 1) {
            this.indexAdd--;
          }
        }
      });
    },
    // 下一题
    next() {
      if (!this.cansave) {
        return false;
      }
      if (this.qsList1[this.hisIdx].isLast && this.can_switch_pre) {
        return false;
      }
      if (this.isTimeout) {
        // 超时直接返回首页
        Dialog.alert({
          title: "提示",
          message: "考试已超时！",
        }).then(() => {
          this.$router.push("/home");
        });
        // Toast.fail('考试已超时！');
        return;
      }
      let flag = this.qsList1[this.hisIdx].qsflag;
      let auid = this.qsList1[this.hisIdx].autoid;

      let params = {
        autoid: auid,
        qsres: "",
        username: this.userInfo.username, // 后续登录接口有变--username
      };

      if (flag == 0) {
        // 单选题
        params.qsres = this.radioAs;
        if (this.radioAs) {
          for (let i = 0; i < this.qsList1.length; i++) {
            if (this.qsList1[i].autoid == auid) {
              this.qsList1[i].qsres = true;
            }
          }
        }
        this.SaveTestQsInfo(params);
      } else if (flag == 1) {
        if (
          this.qsList1[this.hisIdx].select_count > 0 &&
          this.qsList1[this.hisIdx].select_count != this.checkList.length
        ) {
          Dialog.alert({
            title: "系统提示",
            message:
              "此题选项必须选择" +
              this.qsList1[this.hisIdx].select_count +
              "个！",
          }).then(() => {});
          return false;
        }
        if (this.checkList.length > 0) {
          for (let i = 0; i < this.qsList1.length; i++) {
            if (this.qsList1[i].autoid == auid) {
              this.qsList1[i].qsres = true;
            }
          }
        }
        // 多选题
        params.qsres = this.checkList.sort().join(",");
        this.SaveTestQsInfo(params);
      } else if (flag == 2) {
        // 判断题
        params.qsres = this.judgeAs == "0" ? false : true;
        if (this.judgeAs) {
          for (let i = 0; i < this.qsList1.length; i++) {
            if (this.qsList1[i].autoid == auid) {
              this.qsList1[i].qsres = true;
            }
          }
        }
        this.SaveTestQsInfo(params);
      } else if (flag == 3) {
        // 填空题
        params.qsres = this.completions.join("|");
        if (this.completions.length > 0) {
          for (let i = 0; i < this.qsList1.length; i++) {
            if (this.qsList1[i].autoid == auid) {
              this.qsList1[i].qsres = true;
            }
          }
        }
        this.SaveTestQsInfo(params);
      } else if (flag == 4) {
        // 问答题
        params.qsres = this.questionAs;
        if (this.questionAs) {
          for (let i = 0; i < this.qsList1.length; i++) {
            if (this.qsList1[i].autoid == auid) {
              this.qsList1[i].qsres = true;
            }
          }
        }
        this.SaveTestQsInfo(params);
      } else if (flag == 5) {
        // 附件题
      }
      this.indexAdd++;
    },
    // 查询课程考试信息汇总
    GetCourseTestTotal() {
      getCourseTestTotal({ paperid: this.paperid }).then((res) => {
        if (res.iserror == 0) {
          // this.bgurl = res.baseinfo[0].bgurl
          this.examinTitle = res.baseinfo[0].papername;
          this.can_switch_pre = res.baseinfo[0].can_switch_pre;
          this.option_outof_order = res.baseinfo[0].option_outof_order;
          this.totalList = res.data;
          this.CreateUserTestInfo(this.totalList);
          // this.taketime = res.baseinfo[0].taketime
          // this.totalscore = res.baseinfo[0].totalscore
          this.forbid_mobile_paste = res.baseinfo[0].forbid_mobile_paste; // 禁用移动端粘贴内容
          this.when_switch_page_auto_post =
            res.baseinfo[0].when_switch_page_auto_post; // 移动端页面自动提交试卷
        }
      });
    },
    randomSort(array) {
      let length = array.length;
      // 不是数组或者数组长度小于等于1，直接返回
      if (!Array.isArray(array) || length <= 1) return;
      for (let index = 0; index < length - 1; index++) {
        let randomIndex = Math.floor(Math.random() * (length - index)) + index;
        [array[index], array[randomIndex]] = [array[randomIndex], array[index]];
      }
      return array;
    },

    onSubmit() {
      if (this.lastflag == -3) {
        Dialog.confirm({
          title: "系统提示",
          message: "您还有题目未完成，是否要提交？",
        })
          .then(() => {
            // on confirm
            this.subMitFun();
          })
          .catch(() => {
            // on cancel
          });
        return false;
      } else {
        this.subMitFun();
      }
    },

    subMitFun() {
      let guid = this.$route.query.guid || "";
      let flag = this.qsList1[this.hisIdx].qsflag;
      let auid = this.qsList1[this.hisIdx].autoid;

      let params = {
        autoid: auid,
        qsres: "",
        username:
          this.from == "dandian"
            ? this.$route.query.username
            : this.userInfo.username, // 后续登录接口有变--username
      };
      if (flag == 0) {
        // 单选题
        params.qsres = this.radioAs;
        saveTestQsInfo(params).then((res) => {
          if (res.iserror == 0) {
            postUserTestInfo({
              mautoid: this.mautoid,
              empid:
                this.from == "dandian"
                  ? this.$route.query.empid
                  : this.from == "mianshi"
                  ? 0
                  : this.userInfo.empid,
              username:
                this.from == "dandian"
                  ? this.$route.query.username
                  : this.userInfo.username,
              paperid: this.paperid,
              teststyle: this.teststyle,
              guid: guid,
            }).then((res) => {
              this.isfinish = true;
            });
          }
        });
      } else if (flag == 1) {
        if (
          this.qsList1[this.hisIdx].select_count > 0 &&
          this.qsList1[this.hisIdx].select_count != this.checkList.length
        ) {
          Dialog.alert({
            message: "该题必须选择" + this.qsList1[this.hisIdx].select_count + "个选项",
            confirmButtonText: left_switch_times <= 0 ? "确认" : "继续答题",
          }).then(() => {});
          return false;
        }
        // 多选题
        params.qsres = this.checkList.sort().join(",");
        saveTestQsInfo(params).then((res) => {
          if (res.iserror == 0) {
            postUserTestInfo({
              mautoid: this.mautoid,
              empid:
                this.from == "dandian"
                  ? this.$route.query.empid
                  : this.from == "mianshi"
                  ? 0
                  : this.userInfo.empid,
              username:
                this.from == "dandian"
                  ? this.$route.query.username
                  : this.userInfo.username,
              paperid: this.paperid,
              teststyle: this.teststyle,
              guid: guid,
            }).then((res) => {
              this.isfinish = true;
            });
          }
        });
      } else if (flag == 2) {
        // 判断题
        params.qsres = this.judgeAs == "0" ? false : true;
        saveTestQsInfo(params).then((res) => {
          if (res.iserror == 0) {
            postUserTestInfo({
              mautoid: this.mautoid,
              empid:
                this.from == "dandian"
                  ? this.$route.query.empid
                  : this.from == "mianshi"
                  ? 0
                  : this.userInfo.empid,
              username:
                this.from == "dandian"
                  ? this.$route.query.username
                  : this.userInfo.username,
              paperid: this.paperid,
              teststyle: this.teststyle,
              guid: guid,
            }).then((res) => {
              this.isfinish = true;
            });
          }
        });
      } else if (flag == 3) {
        // 填空题
        params.qsres = this.completions.join("|");
        saveTestQsInfo(params).then((res) => {
          if (res.iserror == 0) {
            postUserTestInfo({
              mautoid: this.mautoid,
              empid:
                this.from == "dandian"
                  ? this.$route.query.empid
                  : this.from == "mianshi"
                  ? 0
                  : this.userInfo.empid,
              username:
                this.from == "dandian"
                  ? this.$route.query.username
                  : this.userInfo.username,
              paperid: this.paperid,
              teststyle: this.teststyle,
              guid: guid,
            }).then((res) => {
              this.isfinish = true;
            });
          }
        });
      } else if (flag == 4) {
        // 问答题
        params.qsres = this.questionAs;
        saveTestQsInfo(params).then((res) => {
          if (res.iserror == 0) {
            postUserTestInfo({
              mautoid: this.mautoid,
              empid:
                this.from == "dandian"
                  ? this.$route.query.empid
                  : this.from == "mianshi"
                  ? 0
                  : this.userInfo.empid,
              username:
                this.from == "dandian"
                  ? this.$route.query.username
                  : this.userInfo.username,
              paperid: this.paperid,
              teststyle: this.teststyle,
              guid: guid,
            }).then((res) => {
              this.isfinish = true;
            });
          }
        });
      } else if (flag == 5) {
        // 附件题
      }
    },

    // 生成当前用户当前课程考试试卷信息（并返回试题明细和上次中断序号信息）
    CreateUserTestInfo(totalList) {
      let guid = this.$route.query.guid || "";
      createUserTestInfo({
        mautoid: this.mautoid,
        empid:
          this.from == "dandian"
            ? this.$route.query.empid
            : this.from == "mianshi"
            ? 0
            : this.userInfo.empid,
        username:
          this.from == "dandian"
            ? this.$route.query.username
            : this.userInfo.username,
        paperid: this.paperid,
        teststyle: this.teststyle,
        guid: guid,
        //后续登录接口有变--username
      }).then((res) => {
        if (res.iserror == 0) {
          // 考试结束时间
          this.testet = res.lastinfo[0].testet;
          this.lastflag = res.lastinfo[0].lastflag;
          this.FreshTime(this.testet);
          // 第一次进来查询时，如果没有答完就，获取记录上次的id在结果数组中的index，用来显示上次未答的题
          this.lastid = res.lastinfo[0].lastid;
          this.filesvrurl = res.lastinfo[0].filesvrurl;
          if (this.lastid > 0) {
            this.showExData = true;
            res.paperinfo.map((it, index) => {
              if (it.autoid == this.lastid) {
                this.hisIdx = index;
              }
            });
          } else if (this.lastid == -2) {
            this.isWcDt = true;
            this.showExData = true;
            this.lastid = res.paperinfo[0].autoid;
            res.paperinfo.map((it, index) => {
              if (it.autoid == this.lastid) {
                this.hisIdx = index;
              }
            });
          } else if (this.lastid == -1) {
            // 答题结束
            // 但是没有返回总分，咋显示？？？
            this.totalscore = res.lastinfo[0].totalscore;
            this.lastinfos = res.lastinfo[0].info;
            this.isfinish = true;
            this.showExData = false;
          }
          if (res.paperinfo.length > 0) {
            res.paperinfo.forEach((item, index) => {
              if (index == 0) {
                item.isFirst = true;
              }
              if (index == res.paperinfo.length - 1) {
                item.isLast = true;
              }
              item.nowSelect = false;
              item.qsTypeIndex = 999999;
              // 0单选，1多选，2判断，3填空，4问答，5附件
              for (let i = 0; i < totalList.length; i++) {
                if (item.qsflag == 0 && totalList[i].qsflagname == "单选") {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                  item.item_pics = item.item_pic_guid
                    ? item.item_pic_guid.split(",")
                    : [];
                } else if (
                  item.qsflag == 1 &&
                  totalList[i].qsflagname == "多选"
                ) {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                  item.item_pics = item.item_pic_guid
                    ? item.item_pic_guid.split(",")
                    : [];
                }
                if (item.qsflag == 2 && totalList[i].qsflagname == "判断") {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                  item.item_pics = item.item_pic_guid
                    ? item.item_pic_guid.split(",")
                    : [];
                }
                if (item.qsflag == 3 && totalList[i].qsflagname == "填空") {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                }
                if (item.qsflag == 4 && totalList[i].qsflagname == "问答") {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                }
                if (item.qsflag == 5 && totalList[i].qsflagname == "附件") {
                  item.icount = totalList[i].icount;
                  item.score = totalList[i].score;
                  item.totalscore = totalList[i].totalscore;
                }
              }
              if (item.qsflag == 0) {
                item.qsType = "单选题";
                let arr = item.qsitemlist.split("|");
                let arr2 = [];
                for (let i = 0; i < arr.length; i++) {
                  let obj = {
                    id: this.abList[i],
                    c: arr[i],
                  };
                  arr2.push(obj);
                }
                if (this.option_outof_order) {
                  item.options = this.randomSort(arr2);
                } else {
                  item.options = arr2;
                }
              } else if (item.qsflag == 1) {
                item.qsType = "多选题";
                let arr = item.qsitemlist.split("|");
                let arr2 = [];
                for (let i = 0; i < arr.length; i++) {
                  let obj = {
                    id: this.abList[i],
                    c: arr[i],
                  };
                  arr2.push(obj);
                }
                if (this.option_outof_order) {
                  item.options = this.randomSort(arr2);
                } else {
                  item.options = arr2;
                }
              }
              if (item.qsflag == 2) {
                item.qsType = "判断题";
              }
              if (item.qsflag == 3) {
                item.qsType = "填空题";
              }
              if (item.qsflag == 4) {
                item.qsType = "问答题";
              }
            });
            this.qsList1 = res.paperinfo;

            if (this.lastid > 0) {
              this.qsList1.forEach((item) => {
                if (item.autoid == this.lastid) {
                  item.nowSelect = true;
                }
              });
            }

            let sArr = this.sortArr(this.qsList1, "qsflag");
            for (let j = 0; j < sArr.length; j++) {
              for (let k = 0; k < this.qsList1.length; k++) {
                if (sArr[j][0].qsflag == this.qsList1[k].qsflag) {
                  this.qsList1[k].qsTypeIndex = j;
                }
              }
            }
            this.arrayGroupByList = this.arrayGroupBy(this.qsList1, "qsflag");
            console.log(this.arrayGroupByList, "this.qsList1this.qsList1");

            const newobj = this.qsList1[this.hisIdx];
            if (newobj.qsflag == 0) {
              this.radioAs = newobj.qsres;
            } else if (newobj.qsflag == 1) {
              this.checkList = newobj.qsres ? newobj.qsres.split(",") : [];
            } else if (newobj.qsflag == 2) {
              newobj.qsres == "false"
                ? (this.judgeAs = "0")
                : newobj.qsres == "true"
                ? (this.judgeAs = "1")
                : (this.judgeAs = "");
            } else if (newobj.qsflag == 3) {
              // 填空题
              this.completions = newobj.qsres ? newobj.qsres.split("|") : [];
            } else if (newobj.qsflag == 4) {
              this.questionAs = newobj.qsres;
            }
          }
        }
      });
    },
    groupBy(array, f) {
      const groups = {};
      array.forEach(function (o) {
        const group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },
    arrayGroupBy(list, groupId) {
      const sorted = this.groupBy(list, function (item) {
        return [item[groupId]];
      });
      return sorted;
    },
    sortArr(arr, str) {
      var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;

      // 按照特定的参数将数组排序将具有相同值得排在一起
      arr = arr.sort(function (a, b) {
        var s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });

      if (arr.length) {
        _tmp = arr[0][str];
      }
      // console.log( arr );
      // 将相同类别的对象添加到统一个数组
      for (var i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      // 将最后的内容推出新数组
      _arr.push(_t);
      return _arr;
    },
    // 保存试卷某个试题答案信息接口（考试只能一直点下一题，不能切换到上一题）
    SaveTestQsInfo(params) {
      // console.log(params, "paramsparams");
      saveTestQsInfo(params).then((res) => {
        if (res.iserror == 0) {
          // 下一题的题号以及总得分；iflag:-1:表示正常答题结束，>0:表示下一题序号，=0:表示超时答题结束；// totalscore:如果iflag=-1或0，则totalscore表示试卷总得分；
          let total = res.total;
          if (total.length > 0) {
            this.finishInfo = total[0].finishinfo;
            const iflag = total[0].iflag;
            const lastflag = total[0].lastflag;
            this.lastflag = lastflag;
            if (lastflag == -1) {
              // 答题结束
              this.isWcDt = true;
            }
            if (iflag == -1) {
              // 答题结束
              this.totalscore = total[0].totalscore;
              this.showExData = false;
              this.isfinish = true;
            } else if (lastflag == -2) {
              // 剩余1题
              this.lastFlag = true;
              if (iflag > 0) {
                const qsInfo = res.qsinfo[0];

                const qsres = qsInfo.qsres;
                for (let i = 0; i < this.qsList1.length; i++) {
                  this.qsList1[i].nowSelect = false;
                }
                for (let i = 0; i < this.qsList1.length; i++) {
                  if (this.qsList1[i].autoid == qsInfo.autoid) {
                    this.qsList1[i].nowSelect = true;
                  }
                }
                this.showExData = true;
                // 下一题
                this.qsList1.map((it, index) => {
                  if (it.autoid == iflag) {
                    this.hisIdx = index;
                    const newobj = this.qsList1[this.hisIdx];
                    if (newobj.qsflag == 0) {
                      this.radioAs = "";
                      this.checkList = [];
                      this.judgeAs = "";
                      this.questionAs = "";
                      this.completions = [];
                      newobj.options.forEach((item) => {
                        if (qsres == item.id) {
                          this.radioAs = item.id;
                        }
                      });
                    } else if (newobj.qsflag == 1) {
                      this.radioAs = "";
                      let arr = [];
                      this.judgeAs = "";
                      this.questionAs = "";
                      this.completions = [];
                      newobj.options.forEach((item) => {
                        if (qsres.indexOf(item.id) != -1) {
                          arr.push(item.id);
                        }
                      });
                      this.checkList = arr;
                    } else if (newobj.qsflag == 2) {
                      this.radioAs = "";
                      this.checkList = [];
                      this.questionAs = "";
                      this.completions = [];
                      qsres == "false"
                        ? (this.judgeAs = "0")
                        : qsres == "true"
                        ? (this.judgeAs = "1")
                        : (this.judgeAs = "");
                    } else if (newobj.qsflag == 3) {
                      // 填空题
                      this.radioAs = "";
                      this.judgeAs = "";
                      this.questionAs = "";
                      this.checkList = [];
                      this.completions = qsres ? qsres.split("|") : [];
                    } else if (newobj.qsflag == 4) {
                      this.radioAs = "";
                      this.checkList = [];
                      this.judgeAs = "";
                      this.completions = [];
                      this.questionAs = qsres;
                    }
                  }
                });
              }
            } else if (iflag > 0) {
              this.radioAs = "";
              this.checkList = [];
              this.judgeAs = "";
              this.completions = [];
              this.questionAs = "";
              const qsInfo = res.qsinfo[0];

              const qsres = qsInfo.qsres;
              for (let i = 0; i < this.qsList1.length; i++) {
                this.qsList1[i].nowSelect = false;
              }
              for (let i = 0; i < this.qsList1.length; i++) {
                if (this.qsList1[i].autoid == qsInfo.autoid) {
                  this.qsList1[i].nowSelect = true;
                }
              }
              this.showExData = true;
              // 下一题
              this.qsList1.map((it, index) => {
                if (it.autoid == iflag) {
                  this.hisIdx = index;
                  const newobj = this.qsList1[this.hisIdx];
                  if (newobj.qsflag == 0) {
                    this.radioAs = "";
                    this.checkList = [];
                    this.judgeAs = "";
                    this.questionAs = "";
                    newobj.options.forEach((item) => {
                      if (qsres == item.id) {
                        this.radioAs = item.id;
                      }
                    });
                  } else if (newobj.qsflag == 1) {
                    this.radioAs = "";
                    let arr = [];
                    this.judgeAs = "";
                    this.questionAs = "";
                    newobj.options.forEach((item) => {
                      if (qsres.indexOf(item.id) != -1) {
                        arr.push(item.id);
                      }
                    });
                    this.checkList = arr;
                  } else if (newobj.qsflag == 2) {
                    this.radioAs = "";
                    this.checkList = [];
                    this.questionAs = "";
                    qsres == "false"
                      ? (this.judgeAs = "0")
                      : qsres == "true"
                      ? (this.judgeAs = "1")
                      : (this.judgeAs = "");
                  } else if (newobj.qsflag == 3) {
                    // 填空题
                    this.radioAs = "";
                    this.judgeAs = "";
                    this.questionAs = "";
                    this.checkList = [];
                    this.completions = qsres ? qsres.split("|") : [];
                  } else if (newobj.qsflag == 4) {
                    this.radioAs = "";
                    this.checkList = [];
                    this.judgeAs = "";
                    this.questionAs = qsres;
                  }
                }
              });
            } else if (iflag == 0) {
              // 超时
              this.totalscore = total[0].totalscore;
              this.isOvertime = true;
              this.showExData = false;
            }
          }
        }
      });
    },
    prevTestQsInfo(params) {},
  },
};
</script>

<style lang="less" scoped>
.examination {
  background: #fff;
  height: 100vh;

  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #ffffff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .banner {
    height: 124px;
    background: url("~@/assets/img/examination1.png") no-repeat;
    background-size: cover;
    background-position-y: -150px;
  }
  .bigContainer {
    max-height: calc(100% - 360px) !important;
    overflow: auto;
  }

  .content {
    font-size: 36px;
    font-family: Source Han Sans SC;
    // padding: 30px;

    .qsType {
      margin-bottom: 28px;
      .typeName {
        margin-right: 15px;
      }
      .grade {
        color: #2b8df0;
      }
    }
    .qsContent {
      // padding: 0 36px 0;
      // border-top: 1px solid #ccc;
      color: #333;
      font-size: 32px;
      .qsTit {
        margin-bottom: 42px;
      }
      .title_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      // 单选样式
      .singleChoice {
        padding-left: 1em;
        .option_pic {
          margin-bottom: 20px;
          .van-image {
            border: 1px solid #e1e6f0;
          }
        }
        .van-radio {
          margin-bottom: 40px;
        }
        .van-radio {
          /deep/ .van-radio__icon--round {
            display: none;
          }
          /deep/.van-radio__label {
            margin-left: 0px;
            white-space: pre-wrap;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-radio[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 多选样式
      .van-checkbox-group {
        padding-left: 1em;
        .van-checkbox {
          margin-bottom: 40px;
        }
        .van-checkbox {
          /deep/ .van-checkbox__icon--round {
            display: none;
          }
          /deep/.van-checkbox__label {
            margin-left: 0px;
            white-space: pre-wrap;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-checkbox[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-checkbox__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 判断题样式
      .judge {
        padding-left: 1em;
        .option_pic {
          margin-bottom: 20px;
          .van-image {
            border: 1px solid #e1e6f0;
          }
        }
        .van-radio {
          margin-bottom: 40px;
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 简答题样式
      .brief {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
      }
      // 上传题样式
      /deep/.van-uploader {
        width: 100%;
        /deep/.van-uploader__wrapper {
          display: flex;
          justify-content: flex-end;
          flex-flow: row-reverse;
          // flex-direction: column-reverse;
          /deep/.van-uploader__input-wrapper {
            // background: red;
            width: 50%;
            // width:80%;
          }
        }
      }
    }
  }
  .footer {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 0px 1px 27px 2px rgba(140, 148, 162, 0.33);
    .footer-0 {
      width: 100%;
      height: 126px;
      display: flex;
    }
    .footer-1 {
      height: 126px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .footer-2 {
      font-size: 32px;
      &.active {
        color: #004ad4;
      }
      &.disabled {
        color: #ccc !important;
      }
      // color: #004AD4;
    }
    .footer-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      border-right: 1px solid #eee;
    }
    .footer_button {
      width: 100%;
      display: flex;
      box-shadow: 0px 1px 27px 2px rgba(140, 148, 162, 0.33);
    }
    .footer_datika {
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: 0;
    }
    .van-button {
      width: 80%;
      margin-bottom: 20px;
      font-size: 32px;
      border: 0;
    }
  }
  .endtime {
    width: 100%;
    font-size: 32px;
    color: red;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
}

.daTiKaContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.daTiKaContainerBox {
  width: 90%;
  height: 80%;
  background: #fff;
  border-radius: 14px;
  position: relative;
}
.daTiKaContainerBoxTop {
  width: 100%;
  height: 80px;
}
.ipiipipiTop {
  display: flex;
  align-items: center;
}
.ipiipipi {
  display: flex;
  align-items: center;
  justify-content: center;
}
.daTiKaContainerBoxContent {
  width: 100%;
  overflow: auto;
  height: calc(100% - 112px);
  padding: 0 30px;
}
.divdivdiv {
  width: 100%;
  .ppppppp {
    margin: 0;
    display: flex;
    align-items: center;
    .shuxian {
      width: 8px;
      height: 32px;
      background: #4f88f5;
      border-radius: 4px;
    }
    .spanspan {
      font-size: 32px;
      font-weight: bold;
      color: #242424;
      margin-left: 14px;
    }
  }
}
.ulliulliulli {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 9px;
  li {
    width: 72px;
    height: 72px;
    background: #ebedef;
    border-radius: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-bottom: 15px;
    &.nowQsRes {
      background: #deefff;
      span {
        color: #4f88f5;
      }
    }
    &.nowSelectnowSelect {
      background: #ffe9b9;
      span {
        color: #ff9600;
      }
    }
    span {
      font-size: 32px;
      color: #cfccd0;
    }
  }
}
</style>
